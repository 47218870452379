<template>
  <el-checkbox class="checkbox-img-item" :checked="true" :disabled="disabled" @change="toggleRowSelection(data)">
    <span class="text-item text-cut">
      {{ data[prop] }}
    </span>
  </el-checkbox>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    disabledValue: {
      type: Array,
      default: () => []
    },

    prop: {
      type: String,
      default: 'nickName'
    }
  },

  computed: {
    disabled({ disabledValue, data }) {
      return !!disabledValue.find((id) => id == data.id)
    }
  },

  methods: {
    toggleRowSelection(data) {
      this.$emit('toggleRowSelection', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.text-item {
  position: relative;
  top: 5px;
  display: inline-block;
  width: 110px;
}
</style>
